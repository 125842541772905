.product {
  @apply flex;
  @apply flex-col;
  @apply w-full;
  > h4 {
    @apply leading-6;
    @apply text-lg;
    @apply my-2;
  }
}
.product-big {
  @apply flex;
  @apply flex-col;
  @apply w-full;
  .content {
    @apply order-2;
  }
  .product-image {
    @apply w-full;
    @apply order-1;

  }
  h4 {
    @apply leading-6;
    @apply text-lg;
    @apply font-semibold;
  }
  .desc {
    @apply mb-5;
  }
}
.modal {
  height: 34rem;
  .modal-image {
    height: 24rem;
  }
}
@screen sm {

}
@screen md {
  .product-big {
    @apply flex-row;
    .content {
      @apply order-1;
    }
    .product-image {
      @apply w-1/2;
      @apply order-2;

    }
    h4 {
    }
    .desc {
      @apply mb-0;
    }
  }
  .modal {
    height: 50rem;
    .modal-image {
      height: 38rem;
    }
  }
}
@screen lg {
  .modal {
    @apply max-w-screen-lg;
    max-height: 80vh;
    height: calc(100vh - 10rem);
    .modal-image {
      max-height: 61vh;
      height: calc(100vh - 12rem);
    }
  }
  
}