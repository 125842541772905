.btn {
  @apply inline-block;
  @apply px-3;
  @apply py-2;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
  &-primary {
    @apply bg-gray-700;
    @apply text-white;
    @apply tracking-wide;
    &:hover {
      @apply bg-gray-600;
    }
  }
}